var $ = require('jquery');
window.jQuery = $;
window.$ = $;

import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import './page.css';

const analytics = Analytics({
    app: 'awesome-app',
    plugins: [
      googleTagManager({
        containerId: 'GTM-123xyz'
      })
    ]
});

analytics.page();

$(".__section-9_collapse_trigger").click(function() {
    $(this).toggleClass("text-black");
    let icon_src =  $(this).find('.arrow_collapse_content');

    var src = ($(icon_src).attr('src') === 'icons_static/down-arrow.svg') ? 'icons_static/down-arrow2.svg' : 'icons_static/down-arrow.svg';
    $(icon_src).attr('src', src);
});

$(document).bind("contextmenu", function(e) {
    return false;
});